<template>
    <v-card>
        <v-card-title>
            <v-icon left>{{icons.mdiAccountMultiplePlus}}</v-icon>
            <span class="primary--text">{{$t('menu.systemVariable')}}</span>
        </v-card-title>
        <v-card-text>
            <v-list lines="one">
                <v-list-item>
                    <v-list-item-title>
                        <router-link :to="{ name:'system-setting-positions' }">{{$t('menu.positionCreation')}}</router-link>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <router-link :to="{ name:'system-setting-medals' }">{{$t('menu.medalCreation')}}</router-link>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <router-link :to="{ name:'system-setting-case-letter-types' }">{{$t('menu.caseLetterType')}}</router-link>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <router-link :to="{ name:'system-setting-evidence-types' }">{{$t('menu.evidenceType')}}</router-link>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <router-link :to="{ name:'system-setting-crime-types' }">{{$t('menu.crimeTypeCreation')}}</router-link>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <router-link :to="{ name:'system-setting-case-task-types' }">{{$t('menu.caseTaskType')}}</router-link>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-list-item-title>
                        <router-link :to="{ name:'system-setting-decision-types' }">{{$t('menu.decisionTypeCreation')}}</router-link>
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import {
  mdiPencil,
  mdiDelete,
  mdiContentSave,
  mdiCancel,
  mdiEye,
  mdiMagnify,
  mdiPlusThick
} from '@mdi/js'
import systemService from '@/services/service.system'

export default {
  name: 'system-setting-dashboard',
  data() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
        mdiContentSave,
        mdiCancel,
        mdiEye,
        mdiMagnify,
        mdiPlusThick
      }
    }
  }
}
</script>
